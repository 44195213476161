/* eslint-disable */
import {Vue, Options} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { HisOwnDirector } from '@/store/hisOwnDirector/types'

const namespace: string = 'hisOwnDirector';

@Options({
	name: 'HisOwnDirectorModelChoicePage'
})

export default class HisOwnDirectorModelChoicePage extends Vue {
	@State('hisOwnDirector') hisOwnDirector: HisOwnDirector | undefined;
	@Action('getAllModels', {namespace}) getAllModels: any;
	@Action('storeSelectedModel', {namespace}) storeSelectedModel: any;

	modelProfile: Array<any> = [];

	onSubmit() {
		this.storeSelectedModel(this.modelProfile);
		this.$router.push({ name: 'his-own-director-request' }).then(r => r);
	}

	mounted() {
		this.getAllModels();
	}
}
